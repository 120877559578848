import { ModelApiKey } from '../types/models'
import { LocaleCode } from '../types/locales'

export const paths = {
  [LocaleCode.SL]: {
    [ModelApiKey.Page]: { path: '' },
    [ModelApiKey.Article]: { path: 'knjiznica' }
  },
  [LocaleCode.EN]: {
    [ModelApiKey.Page]: { path: '' },
    [ModelApiKey.Article]: { path: 'library' }
  },
  [LocaleCode.HR]: {
    [ModelApiKey.Page]: { path: '' },
    [ModelApiKey.Article]: { path: 'knjiznica' }
  }
}
